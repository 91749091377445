<template>
    <base-dialog width="640" :is-open.sync="isOpenLocal">
        <v-card-title style="background-color: #f1eeee">
            <span style="color: #78756a" class="font-weight-bold"
                >View #TakeAMomentToPray
            </span>
            <v-btn
                class="border-radius-25 px-5 ml-5"
                depressed
                rounded
                dark
                color="#389877"
                @click="$emit('showUpdateForm')"
                >Update</v-btn
            >
        </v-card-title>
        <v-card-text>
            <v-row class="px-5 my-2">
                <v-col cols="12">
                    <v-img
                        class="border-radius-10"
                        :lazy-src="
                            data.devotion_image"
                        :src="
                            data.devotion_image"
                    ></v-img>
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="4" class="font-weight-bold">
                            DATE
                        </v-col>
                        <v-col cols="12" md="8" class="font-size-16">
                            {{ schedule }}
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="4" class="font-weight-bold">
                            TITLE
                        </v-col>
                        <v-col cols="12" md="8" class="font-size-16">
                            {{ data.title }}
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="4" class="font-weight-bold">
                            BIBLE VERSE
                        </v-col>
                        <v-col cols="12" md="8" class="font-size-16">
                            {{ data.bible_verse }}
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="4" class="font-weight-bold">
                            LINK
                        </v-col>
                        <v-col cols="12" md="8">
                            <div
                                class="d-flex align-center"
                                v-if="data.bible_url"
                            >
                                <v-icon class="mr-1">mdi-book-cross</v-icon>
                                <a
                                    class="font-size-16 text-decoration-underline black--text"
                                    :href="data.bible_url"
                                    style="color: #00000099 !important"
                                    target="_blank"
                                >
                                    {{ data.bible_url }}</a
                                >
                            </div>
                            <div class="mb-1"></div>
                            <!-- <div
                                class="d-flex align-center"
                                v-if="data.youtube_link"
                            >
                                <v-icon class="mr-1">mdi-youtube</v-icon>
                                <a
                                    class="font-size-16 text-decoration-underline black--text"
                                    :href="data.youtube_link"
                                    style="color: #00000099 !important"
                                    target="_blank"
                                >
                                    {{ data.youtube_link }}</a
                                >
                            </div> -->
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions class="pa-0">
            <v-container fluid>
                <v-row align="center" justify="center" class="py-1 px-2">
                    <v-col md="2" cols="12" class="px-2">
                        <v-btn
                            block
                            depressed
                            dark
                            color="error"
                            rounded
                            @click="openAlertDialog"
                            >Delete</v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>

        <base-alert-dialog
            :is-open.sync="alertDialog.open"
            :title="alertDialog.title"
            :description="alertDialog.description"
            :theme="alertDialog.theme"
            :loading="isActionStart"
            @onCancel="alertDialog.open = false"
            @onProceed="onDelete"
        ></base-alert-dialog>
    </base-dialog>
</template>

<script>
import BaseDialog from "@/components/Base/BaseDialog";
import dateUtility from "@/mixins/date";
import BaseAlertDialog from "@/components/Base/BaseAlertDialog";
import { DELETE_DAILY_DEVOTION } from "@/store/modules/dailyDevotion";
export default {
    name: "engage-daily-devotion-information-dialog",

    components: { BaseAlertDialog, BaseDialog },

    props: {
        isOpen: { type: Boolean, default: false, required: true },
        data: { type: [Object, null], required: false },
    },

    data() {
        return {
            isOpenLocal: this.isOpen,
            isActionStart: false,
            alertDialog: {
                open: false,
                theme: "warning",
                title: null,
                description: null,
            },
        };
    },

    watch: {
        isOpen(value) {
            this.isOpenLocal = value;
        },

        isOpenLocal(value) {
            this.$emit("update:isOpen", value);
        },
    },

    computed: {
        schedule() {
            return dateUtility.formatTimestampToSchedule(
                dateUtility.toLocalDate(this.data.schedule_at)
            );
        },
    },

    methods: {
        closeForm() {
            this.$emit("close");
        },

        async onDelete() {
            this.isActionStart = true;
            const { success } = await this.$store.dispatch(
                DELETE_DAILY_DEVOTION,
                this.data.id
            );
            if (success) {
                this.alertDialog.open = false;
                this.isActionStart = false;
                this.closeForm();
                this.$emit("onDeleteSuccess");
                this.$notify({
                    group: "main-notifications",
                    type: "error",
                    duration: 5000,
                    title: "Engage",
                    text: "Devotion was deleted.",
                });
                return;
            }
            this.isActionStart = false;
        },

        openAlertDialog() {
            this.alertDialog = Object.assign(
                {},
                {
                    ...this.alertDialog,
                    title: "Daily Devotion",
                    description: `Are you sure you want to delete ${this.data.title}? Click proceed if you are sure.`,
                    open: true,
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.close-icon {
    transition: 0.3s;
    border-radius: 50px !important;
    margin-top: 20px;
    margin-right: 15px;
    padding: 3px;
    background-color: #2c423d;
    color: white;
}
</style>
