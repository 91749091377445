<template>
    <base-dialog
        width="722"
        :title="dynamicTitles.card"
        :is-open.sync="isOpenLocal"
    >
        <v-alert tile type="error" v-if="!!error">
            {{ error }}
        </v-alert>

        <v-card-text class="pt-7 px-2">
            <v-container fluid>
                <v-row>
                    <!-- <v-col cols="12"> -->
                        <!-- <base-input-label required hint="max. 5mb">{{
                            dynamicTitles.thumbnail
                        }}</base-input-label>
                        <div class="d-flex justify-space-between align-center">
                            <v-file-input
                                solo
                                rounded
                                :prepend-icon="null"
                                show-size
                                small-chips
                                color="secondary"
                                flat
                                hide-details
                                accept="image/*"
                                class="rounded-lg"
                                id="attachment"
                                clearable
                                v-model="form.image"
                            >
                                <template v-slot:selection="{ text }">
                                    <v-chip small label color="secondary">
                                        {{ text }}
                                    </v-chip>
                                </template>
                            </v-file-input>

                            <div
                                class="mx-2 caption font-weight-bold secondary--text"
                            ></div>

                            <v-btn
                                class="caption font-weight-bold text-capitalize"
                                small
                                depressed
                                rounded
                                dark
                                color="secondary"
                                @click="openFileInput('attachment')"
                                >Select File</v-btn
                            >
                        </div> -->

                        <!-- <v-menu
                            max-width="20rem"
                            top
                            v-if="operation.editMode && form.existing_image"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <div class="pt-2">
                                    <span
                                        class="caption text-decoration-underline secondary--text c-pointer"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        View existing image
                                    </span>
                                </div>
                            </template>
                            <v-card width="20rem">
                                <v-img
                                    :src="form.existing_image"
                                    width="100%"
                                    height="auto"
                                ></v-img>
                            </v-card>
                        </v-menu> -->
                    <!-- </v-col> -->

                    <v-col cols="12">
                        <base-input-label required>Title</base-input-label>
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            v-model="form.title"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="6">
                                <base-input-label required
                                    >Bible Book</base-input-label
                                >
                                <v-autocomplete
                                    solo
                                    rounded
                                    class="rounded-lg"
                                    flat
                                    hide-details
                                    :items="bibleBooks"
                                    item-value="id"
                                    item-text="name"
                                    v-model="form.bible_book_id"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="12" md="6">
                                <base-input-label required
                                    >Bible Verse</base-input-label
                                >
                                <v-text-field
                                    solo
                                    rounded
                                    class="rounded-lg"
                                    flat
                                    hide-details
                                    v-model="form.bible_verse"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label required>Date</base-input-label>
                        <base-datetime-picker
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            :min="currentDate"
                            v-model="form.schedule_at"
                        ></base-datetime-picker>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label required>Bible URL</base-input-label>
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            v-model="form.bible_url"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <!-- <base-input-label required
                            >YouTube Link</base-input-label
                        >
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            v-model="form.youtube_link"
                        ></v-text-field> -->
                        <base-input-label required hint="max. 5mb"
                            >Devotion Image</base-input-label
                        >
                        <div class="d-flex justify-space-between align-center">
                            <v-file-input
                                solo
                                rounded
                                :prepend-icon="null"
                                show-size
                                small-chips
                                color="secondary"
                                clearable
                                flat
                                hide-details
                                accept="image/*"
                                class="rounded-lg"
                                id="image"
                                v-model="form.devotion_image"
                            >
                                <template v-slot:selection="{ text }">
                                    <v-chip color="secondary" small label>
                                        {{ text }}
                                    </v-chip>
                                </template>
                            </v-file-input>
                            <div class="mx-2"></div>
                            <v-btn
                                class="caption font-weight-bold text-capitalize"
                                small
                                depressed
                                rounded
                                dark
                                color="secondary"
                                @click="openFileInput('image')"
                                >Select File</v-btn
                            >
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions class="pa-0">
            <v-container fluid>
                <v-row align="center" justify="center" class="py-1 px-2">
                    <v-col md="2" cols="12" class="px-2">
                        <v-btn
                            block
                            depressed
                            dark
                            color="#389877"
                            rounded
                            :loading="isActionStart"
                            @click="onSave"
                            >Save</v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </base-dialog>
</template>

<script>
import BaseDialog from "@/components/Base/BaseDialog";
import BaseInputLabel from "@/components/Base/BaseInputLabel";
import BaseDatetimePicker from "@/components/Base/BaseDateTimePicker";
import dateUtility from "@/mixins/date";
import {
    CREATE_DAILY_DEVOTION,
    UPDATE_DAILY_DEVOTION,
} from "@/store/modules/dailyDevotion";

const defaultForm = {
    // image: null,
    existing_image: null,
    bible_book_id: null,
    // youtube_link: null,
    devotion_image: null,
    title: null,
    bible_verse: null,
    bible_url: null,
    schedule_at: null,
};

export default {
    name: "engage-daily-devotion-form-dialog",

    components: { BaseDatetimePicker, BaseInputLabel, BaseDialog },

    props: {
        isOpen: { type: Boolean, default: false, required: true },
        action: { type: String, default: "", required: true },
        data: { type: [Object, null], required: false },
    },

    data() {
        return {
            isOpenLocal: this.isOpen,
            form: Object.assign({}, defaultForm),
            isActionStart: false,
            error: null,
            dailyDevotionID: null,
        };
    },

    computed: {
        eventTypes() {
            return [
                {
                    value: 1,
                    text: "Public",
                },

                {
                    value: 0,
                    text: "Private",
                },
            ];
        },

        operation() {
            return {
                createMode: this.action === "add",
                editMode: this.action === "edit",
            };
        },

        dynamicTitles() {
            let title = {
                card: null,
                thumbnail: null,
            };
            if (this.operation.createMode) {
                title = Object.assign(
                    {},
                    {
                        card: "Add #TakeAMomentToPray",
                        thumbnail: "Image",
                    }
                );
            } else if (this.operation.editMode) {
                title = Object.assign(
                    {},
                    {
                        card: "Edit #TakeAMomentToPray",
                        thumbnail: "Change Image",
                    }
                );
            }

            return title;
        },

        currentDate() {
            return dateUtility.currentDate();
        },

        bibleBooks() {
            return this.$store.state.selection.bibleBooks;
        },
    },

    watch: {
        isOpen(value) {
            this.isOpenLocal = value;
        },

        isOpenLocal(value) {
            if (!value && this.operation.editMode) {
                this.resetForm();
            }
            this.$emit("update:isOpen", value);
        },

        data(value) {
            if (
                this.action === "edit" &&
                this.data &&
                Object.keys(value).length > 0
            ) {
                this.dailyDevotionID = value.id;
                this.form = Object.assign(
                    {},
                    {
                        // image: null,
                        title: value.title || null,
                        bible_book_id: value.bible_book.id || null,
                        // youtube_link: value.youtube_link || null,
                        devotion_image: value.devotion_image || null,
                        bible_verse: value.bible_verse || null,
                        bible_url: value.bible_url || null,
                        schedule_at:
                            dateUtility.toLocalDate(value.schedule_at) || null,
                        existing_image: value.devotion_image,
                    }
                );
            }
        },
    },

    methods: {
        openFileInput(elementId) {
            this.$nextTick(() => {
                document.getElementById(elementId).click();
            });
        },

        resetForm() {
            this.error = null;
            this.form = Object.assign({}, defaultForm);
        },

        async onCreate() {
            const payload = {
                ...this.form,
                schedule_at: dateUtility.toUTC(this.form.schedule_at),
            };
            console.log("payload: ", payload)
            this.isActionStart = true;
            const { success, message } = await this.$store.dispatch(
                CREATE_DAILY_DEVOTION,
                payload
            );
            if (success) {
                this.isOpenLocal = false;
                this.$emit("onSuccess");
                this.resetForm();
                this.isActionStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Engage",
                    text: "Devotion was created.",
                });
                return;
            }
            this.error = message;
            this.isActionStart = false;
        },

        async onUpdate() {
            const payload = {
                ...this.form,
                dailyDevotionID: this.dailyDevotionID,
                schedule_at: dateUtility.toUTC(this.form.schedule_at),
            };
            console.log("payload: ", payload)
            this.isActionStart = true;
            const { success, message } = await this.$store.dispatch(
                UPDATE_DAILY_DEVOTION,
                payload
            );
            if (success) {
                this.isOpenLocal = false;
                this.$emit("onSuccess");
                this.resetForm();
                this.isActionStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Engage",
                    text: "Devotion was updated.",
                });
                return;
            }
            this.error = message;
            this.isActionStart = false;
        },

        async onSave() {
            if (this.operation.createMode) {
                await this.onCreate();
            } else if (this.operation.editMode) {
                await this.onUpdate();
            }
        },
    },
};
</script>
